<template>
    <div class="verify-email-page house-image" style="min-height: 100vh;">
        <div class="card" style="background-color: transparent; border: 0;">
            <div class="card-body py-0">
                <div class="card-title mb-3 title-bg-image">
                    <div class="logo-image">
                    </div>
                </div>
                <form v-on:submit.prevent="onSubmit">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-header h3 text-primary" style="background-color: transparent; border: 0;">
                            臺中購物節店家平台合作店家Email驗證結果
                        </div>
                        <div class="card-body form-verify-email">
                            <div class="mb-3">
                                <h4>{{ message }}</h4>
                            </div>
                            <div>
                            <router-link style="text-decoration: none;" to="/Login">
                                <button class="w-100 btn btn-lg btn-primary">返回登入頁</button>
                            </router-link>
                            </div>
                        </div>
                        <BeforeLoginFooter/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { verify_email } from '../../api/auth';
import BeforeLoginFooter from "../../components/BeforeLoginFooter.vue";

export default {
    components: {
        BeforeLoginFooter
    },
    data() {
        return {
            message: "",
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if(this.$route.query.verify_code !== undefined) {
            verify_email(this.$route.query.verify_code).then(response => {
                this.message = response.data.message;
            }, () => {
                this.message = "資料有誤，驗證失敗！";
            });
        }
    }
};
</script>